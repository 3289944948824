<template>
	<div class="page">
		<div class="title">
			{{ lang == 6 ? '宝爱捷（中国）人才发展与学习中心' : 'PAIG (CHINA) TALENTS & LEARNING CENTER' }}
			<p class="yellowLine"></p>
		</div>
		<div>
			<div class="box1">
				<div v-for="(item,index) in info.image" :key="index">
					<img :src="item" />
				</div>
			</div>
			<div class="box2" v-html="info.describe"></div>
			<div class="box3">
				<p class="tit">{{lang==6?'人才加速及成长项目':'Talent Development International Programs'}}</p>
				<div class="box3-content">
					<img src="../../../assets/bg.png" class="bg">
					<img src="../../../assets/arrows.png" class="arrows" @click="$refs.mySwiper1.swiper.slideNext()" />
					<img src="../../../assets/arrows.png" class="arrows" @click="$refs.mySwiper1.swiper.slidePrev()" />
					<swiper :options="swiperOption" ref="mySwiper1" @slideChange="slideChange"
						v-if="bannerList.length > 0">
						<swiper-slide class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
							<img :src="item.image" mode="widthFit" class="banner" @click="showImgViewer(index)" />
							<p>{{ item.title }}</p>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<div class="box4">
				<p class="tit">{{lang==6?'活动':'Activities'}}</p>
				<div>
					<div class="mode3-list-item" v-for="(item, index) in activityList" :key="index">
						<div class="mode3-list-item-text">
							<div>
								<p class="title">{{ item.title }}</p>
								<div class="mode3-list-item-text-describe" v-html="item.describe"></div>
							</div>
							<div>
								<div>
									<i class="el-icon-time"></i>
									<span>{{ item.business_time }}</span>
								</div>
							</div>
						</div>
						<div class="mode3-right">
							<img src="../../../assets/arrows1.png" :style="item.swiperCur == 0 ? 'opacity: .3' : ''"
								class="arrows1" @click="swiperPrev(index)" />
							<img :style="item.swiperCur == item.atlas.length - 1 ? 'opacity: .3' : ''"
								src="../../../assets/arrows1.png" class="arrows1" @click="swiperNext(index)" />
							<swiper @slide-change-transition-end="handleClickSlide(index)" :options="swiperOption1"
								:ref="'mode3Swper' + index">
								<swiper-slide v-for="(image, j) in item.atlas" :key="j">
									<el-image :src="image" mode="widthFit" :preview-src-list="item.atlas"
										class="previewImage"></el-image>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total"
					:current-page="page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		props: {

		},
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				info: {},
				bannerIndex: 0,
				bannerList: [],
				// 轮播配置
				swiperOption: {
					slidesPerView: 3,
					spaceBetween: 30,
					centeredSlides: true,
					loop: true,
					loopFillGroupWithBlank: true,
					observer: true,
					observeParents: true,
					slideToClickedSlide: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					}
				},
				limit: 10,
				page: 1,
				total: 0,
				activityList: []
			}
		},
		mounted() {
			this.getInfo()
		},
		methods: {
			changePage(page) {
				this.page = page
				this.getActivityList()
			},
			handleClickSlide(index) {
				this.activityList[index].swiperCur = this.$refs['mode3Swper' + index][0].swiper.realIndex
			},
			slideChange() {
				this.bannerIndex = this.$refs.mySwiper1.swiper.realIndex
			},
			getInfo() {
				this.bannerList = []
				this.$api.getConfigure({
					type: 11
				}).then(res => {
					res.data.image = res.data.atlas.split('|')
					this.info = res.data
				})
				this.$api.getBannerList({
					cate_no: 'dfsfsdter4'
				}).then(res => {
					this.bannerList = res.banner_list
				})
				this.getActivityList()
			},
			getActivityList(e = {}) {
				var that = this
				that.activityList = []
				that.$api.getActivityList({
					cate_no: 'bfh45wy4',
					form: 'all',
				}, {
					page: that.page,
					limit: that.limit
				}).then(res => {
					that.$nextTick(() => {
						var list = res.list
						list.forEach(item => {
							item.atlas = item.atlas.split('|')
							item.swiperCur = 0
						})
						that.activityList = list
						that.total = Number(res.paginated.count)
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding-top: 171px;
		padding-bottom: 60px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.text {
		padding: 0px 111px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		line-height: 36px;
		color: #FFF;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		color: #FFF;
		padding: 0 111px;
	}

	.box1>div,
	.box2,
	.box3,
	.box4 {
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 40px;
		margin: 0 60px;
		margin-bottom: 40px;
		// line-height: 100px;
	}

	.box2>div {
		font-size: 20px;
		font-weight: normal;
		line-height: 46px;


		.tit {
			font-size: 32px;
			margin-bottom: 20px;
		}
	}

	.box2-item:last-child {
		margin-top: 60px;
	}

	.box1 {
		display: flex;
		text-align: center;
		justify-content: space-between;
		color: #97233F;
		line-height: 100px;
		padding: 56px 61px 40px;
		// margin: 0 60px;

		>div {
			width: 560px;
			height: 300px;
			padding: 0;
			margin: 0;
			border-radius: 16px;

			img {
				width: 560px;
				height: 300px;
				object-fit: cover;
				border-radius: 16px;
			}
		}
	}




	.box2 {
		padding: 31px 50px;
		line-height: 76px;
		margin-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		padding-bottom: 0;

		.tit {
			line-height: 60px;
		}

		&:nth-child(2) {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 24px;
			font-weight: bold;
		}

		img {
			width: 100%;
			margin-top: 70px;
		}

		.flex {
			display: flex;
			justify-content: space-between;
			text-align: center;

			>div {
				width: 380px;
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 20px;
				font-weight: 350;
				line-height: 46px;

				img {
					width: 100%;
				}

				:nth-child(2) {
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 24px;
					font-weight: bold;
				}

			}
		}
	}

	.box2EN {
		.flex>div:last-child>p:last-child {
			// line-height: 25px;
		}

		p:nth-child(2) {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 24px;
			font-weight: bold;
			line-height: 40px;
			margin-bottom: 20px;
		}
	}

	.box3 {
		padding: 51px 0px 30px;
		margin-bottom: 0;
		margin-top: 0px;
		line-height: 76px;
		border-radius: 0;

		.tit {
			padding: 0 50px;
			text-transform: uppercase;
		}

		&-content {
			position: relative;
			height: 609px;
			background-size: 100% 100%;
			background-repeat: no-repeat;

			.arrows {
				width: 118px;
				height: 87px;
				position: absolute;
				left: 39px;
				z-index: 2;
				top: calc(50% - 100px);

				&:nth-child(2) {
					transform: rotate(180deg);
					left: auto;
					right: 39px;
				}

			}

			.bg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				filter: blur(5px);
				z-index: 1;
			}

			&::after {
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.17);
				z-index: 2;
				left: 0;
				top: 0;
			}

			&::before {
				content: '';
				width: 100%;
				height: 2px;
				background-color: #FFF;
				position: absolute;
				bottom: 110px;
				z-index: 2;

			}

			.banner {
				width: 659px;
				height: 374px;
				border-radius: 14px;
			}

			/deep/ .swiper-container {
				position: relative;
				width: 85%;
				margin: 0 auto;
				height: 609px;
				z-index: 3;
			}

			/deep/.swiper-wrapper {
				height: 450px;
				position: relative;
			}

			/deep/ .swiper-slide {
				text-align: center;
				font-size: 18px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				flex-wrap: wrap;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				-webkit-justify-content: center;
				justify-content: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				align-items: center;
				transition: 300ms;
				transform: scale(0.8);
				position: relative;

				p {
					width: 100%;
					font-family: SourceHanSansCN-Medium, sans-serif;
					color: #FFF;
					font-size: 22px;
					position: absolute;
					font-weight: 500;
					bottom: -180px;
					text-align: center;
					line-height: normal;
					cursor: pointer;

					&::after {
						content: '';
						width: 30px;
						height: 30px;
						background-color: #FFF;
						border-radius: 50px;
						display: block;
						position: absolute;
						left: calc(50% - 10px);
						top: -47px;
					}
				}
			}

			/deep/ .swiper-slide-active,
			/deep/ .swiper-slide-duplicate-active {
				transform: scale(1);
				z-index: 9;
				position: relative;

				p {
					font-size: 32px;
					bottom: -110px;
					transform: scale(1);

					&::after {
						width: 20px;
						height: 20px;
						top: -35px;
						transform: scale(1);
						padding: 5px;
						border: 1px solid #FFF;
						background-clip: content-box;
					}
				}
			}
		}
	}

	.box4 {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		padding: 51px 0px 30px;

		.tit {
			padding: 0 132px;
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		.mode3-list-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 41px 132px;
			box-sizing: content-box;
			height: 370px;

			&:nth-child(odd) {
				background-color: #F2F2F2;

				/deep/.swiper-container {
					background-color: #F2F2F2;
				}
			}

			&:nth-child(even) {
				background-color: #FFF;

				/deep/ .swiper-container {
					background-color: #FFF;
				}
			}

			.mode3-list-item-text {
				width: 882px;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 24px;
				font-weight: normal;
				line-height: 40px;
				letter-spacing: 0em;
				color: #787878;
				position: relative;
				height: 100%;

				.title {
					text-transform: none;
					padding: 0;
					margin: 0;
				}

				>div:nth-child(1) {
					width: 100%;
					overflow: hidden;
					height: calc(100% - 54px);
					padding-right: 20px;

					// overflow: hidden;
					// text-overflow: ellipsis;
					.mode3-list-item-text-describe {
						overflow: hidden;
						height: inherit;
						overflow-y: scroll;
					}
				}

				>div:last-child {
					width: 100%;
					display: flex;
					align-items: bottom;
					position: absolute;
					right: 0;
					bottom: 0px;
					justify-content: flex-end;
					height: 64px;
					line-height: 80px;
					padding-right: 20px;

					img {
						width: 64px;
						height: 64px;

						&:first-child {
							margin-right: 20px;
							transform: rotate(180deg);
						}
					}

					i {
						margin-right: 20px;
					}

					>div:last-child {
						line-height: 84px;
					}
				}
			}

			.title {
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 26px;
				font-weight: bold;
				color: #97233F;
			}
		}

		.mode3-right {
			position: relative;
			height: 280px;

			&::after {
				width: 200px;
				height: 260px;
				background: #97233F;
				top: -15px;
				left: -15px;
				position: absolute;
				content: '';
			}

			/deep/.swiper-container {
				width: 520px;
			}

			/deep/ .swiper-slide {
				width: 520px;
			}

			.previewImage {
				width: 520px;
				height: 280px;
			}

			.arrows1 {
				width: 56px;
				height: 56px;
				position: absolute;
				left: -70px;
				z-index: 9;
				top: calc(50% - (56px/2));

				&:nth-child(2) {
					left: auto;
					right: -70px;
					transform: rotate(180deg);
				}
			}
		}
	}

	.box3EN {
		.flex div {
			line-height: 30px;
			text-transform: capitalize;
			padding: 0 12px;
			box-sizing: border-box;
		}
	}

	.tit {
		font-family: SourceHanSansCN-Medium, sans-serif;
		font-size: 32px;
		font-weight: bold;
		color: #97233F;
	}

	.el-pagination {
		text-align: center;
		margin: 100px 0;

		/deep/ .active {
			background-color: #97233F !important;
		}
	}
</style>